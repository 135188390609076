// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: #247e85;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: #247e85;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: #247e85 !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: #247e85;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: #247e85;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

.css-or1ysn {
  background-color: rgb(227, 242, 253);
  width: calc(100% - 350px);
  min-height: calc(-88px + 100vh);
  -moz-box-flex: 1;
  flex-grow: 1;
  padding: 14px;
  margin-top: 88px;
  margin-right: 20px;
  border-radius: 7px 7px 0px 0px;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-left: 65px;
}

.MuiDrawer-paper {
  //width: 25% !important;
  width: 303px !important;
}

@media (max-width: 1200px) {
  .MuiDrawer-paper {
    //width: 25% !important;
    width: 303px !important;
  }
}

.css-x5zaxz {
  width: 280px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.css-mydolx-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4375em;
  font-family: "Montserrat", sans-serif;
  color: #616161;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding-left: 14px;
  padding-right: 14px;
  background: #fafafa;
  border-radius: 7px;
  width: 434px;
  margin-left: 28px;
  padding-left: 16px;
  padding-right: 16px;
}

//   .whatever {
//     width: 100% !important;
//     height: 100% !important;
//   }

#whiteboard {
  width: 500px;
  height: 500px;
  background: #ccc;
  position: relative;
}

.element {
  position: absolute;
  background: red;
  width: 80px;
  height: 80px;
}
